@import "../../assets/scss/variables";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 650px;
  padding: 1rem 1rem 50px 1rem;
  background-color: white;
  box-shadow: 0 0 20px #e3e3e3;

  @include media-breakpoint-down(md) {
      width: 100%;
  }

  .product-card-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .product-card-title {
      margin: 0.5rem 0;
      font-size: 2rem;
      text-transform: uppercase;
    }

    .product-card-subtitle {
      margin: 0;
      font-size: 0.8rem;
    }

    ul.product-card-highlights {
      display: flex;
      margin: 0;
      padding: 0;
      list-style-type: none;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      > li {
        margin: 0.5rem 1rem;
      }
    }
  }

  .slick-slider {
    max-width: 60%;

    .slick-arrow {
      &.slick-prev {
        left: -3rem;
      }
      &.slick-next {
        right: -3rem;
      }

      &.slick-prev::before,
      &.slick-next::before {
        width: auto;
        height: auto;
        font-size: 2rem;
        color: $secondary;
      }
    }

    .slick-dots {
      li {
        button::before {
          font-size: 14px;
        }

        &.slick-active {
          button::before {
            color: $secondary;
          }
        }
      }
    }
  }
}
