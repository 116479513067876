@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.basket {
  position: fixed;
  z-index: 3;
  box-sizing: border-box;
  top: 0;
  right: 0;
  height: 100vh;
  width: 0;
  overflow: hidden;
  background-color: white;
  transition: 0.5s;
  @include box-shadow();

  &.visible {
    width: 100%;

    @include breakpoint(md) {
      width: $sidebar-width;
    }
  }

  .basket-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include breakpoint(md) {
      width: $sidebar-width;
    }

    .basket-header {
      flex-shrink: 0;
      display: flex;
      align-items: center;

      .basket-header__icon {
        position: absolute;
        margin: 1rem;
        font-size: 1.5rem;
        cursor: pointer;
      }

      .basket-header__title {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .basket-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem;

      ul.basket-items {
        flex-shrink: 2;
        overflow-y: auto;
        padding: 0;
        list-style-type: none;
      }

      .basket-content-footer {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
