@import "variables";

a.btn {
  text-decoration: none;
}

.btn {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: $primary;
  color: white;
  border: 2px solid $primary;
  border-radius: 2rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.5s;

  &:disabled {
    opacity: 0.65;
    cursor: auto;
  }

  .svg-inline--fa {
    margin: 0 0.5rem;
  }

  &.btn-sm {
    padding: 0.5rem 1rem;
  }

  &.btn-outlined {
    background-color: transparent;
    color: $primary;
    border-color: $primary;

    &:hover {
      background-color: $primary;
      color: white;
    }
  }

  &.btn-secondary {
    background-color: $secondary;
    border: 2px solid $secondary;

    &:hover {
      background-color: transparent;
      color: $secondary;
    }

    &.btn-outlined {
      background-color: transparent;
      color: $secondary;
      border-color: $secondary;

      &:hover {
        background-color: $secondary;
        color: white;
      }
    }
  }
}
