@import "../assets/scss/variables";

$switch-default-width: 60px;
$switch-default-height: 34px;
$switch-default-padding: 4px;
$switch-default-circle-size: 26px;

.switch {
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  width: $switch-default-width;
  height: $switch-default-height;

  &.switch--small {
    width: calc(#{$switch-default-width} / 2);
    height: calc(#{$switch-default-height} / 2);

    input:checked + .slider:before {
      transform: translateX(calc(#{$switch-default-circle-size} / 2));
    }

    .slider {
      border-radius: calc(#{$switch-default-height} / 2);

        &:before {
          height: calc(#{$switch-default-circle-size} / 2);
          width: calc(#{$switch-default-circle-size} / 2);
          left: calc(#{$switch-default-padding} / 2);
          bottom: calc(#{$switch-default-padding} / 2);
        }
    }
  }

  &.switch--large {
    width: calc(#{$switch-default-width} * 2);
    height: calc(#{$switch-default-height} * 2);

    input:checked + .slider:before {
      transform: translateX(calc(#{$switch-default-circle-size} * 2));
    }

    .slider {
      border-radius: calc(#{$switch-default-height} * 2);

        &:before {
          height: calc(#{$switch-default-circle-size} * 2);
          width: calc(#{$switch-default-circle-size} * 2);
          left: calc(#{$switch-default-padding} * 2);
          bottom: calc(#{$switch-default-padding} * 2);
        }
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $primary;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $primary;
    }

    &:checked + .slider:before {
      background-color: white;
      transform: translateX($switch-default-circle-size);
    }

    &:disabled + .slider {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    border-radius: $switch-default-height;
    transition: .15s ease-in-out;

    &:before {
      position: absolute;
      content: "";
      height: $switch-default-circle-size;
      width: $switch-default-circle-size;
      left: $switch-default-padding;
      bottom: $switch-default-padding;
      background-color: #ccc;
      border-radius: 50%;
      transition: .4s;
    }
  }
}
