@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";

.basket-feature {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  color: $white;
  background-color: $secondary;

  .feature-text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }

  .btn {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    border-radius: 0;
  }
}
