@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";

.category-nav {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0.5rem 0;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    border-radius: 0;

    @include breakpoint(md) {
      flex-grow: 0;
      width: 400px;
      margin: 1rem;
      border-radius: 2rem;
    }

    .btn-icon {
      width: 30px;
      padding: 0;
      @include collapse-transition();

      svg {
        margin: 0;
      }
    }

    &.btn-prev:not(:disabled) {
      &:hover {
        .btn-icon {
          padding-right: 1rem;
        }
      }
    }

    &.btn-next:not(:disabled) {
      &:hover {
        .btn-icon {
          padding-left: 1rem;
        }
      }
    }
  }
}
