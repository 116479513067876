.product-card-version {
  display: flex !important; // To override slick
  flex-direction: column;
  align-items: center;

  .product-card-image-container {
    position: relative;

    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
    }

    .product-card-title {
      position: absolute;
      top: 10%;
      width: 100%;
      padding: 1rem 1rem 3rem 1rem;
      background-color: rgba(#888, 0.7);
      color: white;
      text-transform: uppercase;
    }
  }
}
