@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.navbar {
  flex: none;

  nav {
    overflow-x: auto;

    ul {
      display: inline-flex;
      justify-content: space-around;
      height: 100%;
      min-width: 100%;
      margin: 0;
      padding: 0;
      overflow-x: auto;
      list-style-type: none;

      @include breakpoint(md) {
        display: flex;
      }

      &:not(.sub-menu) {
        border-bottom: 1px solid $primary;
      }

      > li {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        flex-basis: content;
        height: 50px;
        min-width: 150px;
        text-align: center;

        &:not(:last-child) {
          border-right: 2px solid white;
        }

        &.active {
          button {
            color: $secondary;
          }
        }

        button {
          background-color: transparent;
          border: none;
          color: $primary;
          font-weight: bold;
          cursor: pointer;
          white-space: nowrap;

          &:focus-visible {
            border: none;
          }

          svg {
            @include collapse-transition();
            margin-left: 0.5rem;
            cursor: pointer;

            &.open {
              transform: rotate(90deg);
            }
          }
        }
      }
    }

    ul.sub-menu {
      position: relative;
      z-index: 1;
      height: $navbar-height;
      color: white;

      > li {
        border: none;
        font-size: 0.9rem;
      }
    }
  }
}
