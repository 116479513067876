form {
  margin: 1rem 0;

  .form-row {
    display: flex;
    margin: 0.5rem 0;

    label {
      flex-shrink: 0;
      width: 100px;
      margin: 0;
    }

    .form-field {
      flex: 1;

      .form-error {
        margin: 0;
        color: red;
        text-align: right;
      }
    }
  }

  input,
  select {
    display: block;
    width: 100%;
  }
}

.checkout-form,
.login-form {
  .form-row {
    label {
      width: 200px;
    }
  }

  .form-field {
    input,
    select,
    textarea {
      width: 100%;
    }
  }
}
