@import "assets/scss/variables";
@import "assets/scss/form";
@import "assets/scss/btn";
@import "assets/scss/label";

// Fix for Slick slider issue https://github.com/kenwheeler/slick/issues/982#issuecomment-73517458
* {
  min-height: 0;
  min-width: 0;
}

body {
  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

.app-container {
  height: 100%;
  color: $primary;
}
