@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  width: 100%;
  min-height: 100vh;
  background-color: $white;
  color: $primary;

  @include box-shadow();

  @include breakpoint(md) {
    width: $sidebar-width;
  }

  h1 {
    text-align: center;
    cursor: pointer;
  }

  .sidebar-top-buttons {
    display: flex;
    width: 100%;
    height: $navbar-height;

    .btn {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border-radius: 0;
      font-size: 0.8rem;

      &:hover {
        color: $white;
        background-color: $secondary;
      }
    }
  }

  /* Responsive navbar */
  .navbar {
    width: 100%;
    height: auto;

    nav {
      ul {
        height: 50px;
      }
    }
  }

  .sidebar-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    width: 100%;
    padding: 1rem;

    .sidebar-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-shrink: 0;

      h1 {
        font-size: 1.5rem;
      }

      .product-starting-price {
        display: flex;
        align-items: center;

        &::before,
        &::after {
          display: block;
          content: '';
          width: 2rem;
          margin: 0 0.5rem;
          border-bottom: 1px solid $white;
        }
      }

      .product-price {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;

        .total-price {
          width: 160px;
          text-align: center;
          font-weight: bold;
          font-size: 1.2rem;
        }
      }
    }

    ul.sidebar-features,
    ul.sidebar-main-highlights {
      margin: 0;
      padding: 1rem;
      list-style-type: none;

      > li {
        width: 100%;
      }
    }

    ul.sidebar-main-highlights {
      > li {
        display: flex;
        align-items: center;
        margin: 0.5rem 0;

        svg {
          width: 1rem;
        }

        h4 {
          margin: 0;
        }
      }
    }

    ul.sidebar-features {
      overflow-y: auto;
      margin-bottom: 1rem;
      width: 100%;

      .feature-item-enter {
        opacity: 0;
      }

      .feature-item-enter-active {
        opacity: 1;
        transition: opacity 300ms ease-in;
      }
    }

    .btn-start {
      margin: 1rem 0;
    }

    .footer-buttons {
      flex-shrink: 0;
      display: flex;
      justify-content: space-around;
      width: 100%;

      .btn-display-basket {

      }

      .btn-language {
        width: 4rem;
        padding: 0;
      }
    }
  }
}
