@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
//@import "~bootstrap/scss/custom-forms";

.feature {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  color: $white;

  .feature-card {
    flex: 1;
    width: 100%;
    padding: 0.5rem;
    background-color: $secondary;
    border: 2px solid $secondary;

    &.active {
      border-color: $primary;

      .feature-card-header {
        .badge-check {
          opacity: 1;
        }
      }
    }

    .feature-card-header {
      position: relative;
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      .badge-check {
        position: absolute;
        top: -21px;
        left: -21px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        height: 1.6rem;
        width: 1.6rem;
        background-color: $white;
        border: 2px solid $primary;
        border-radius: 50%;
        @include collapse-transition();

        svg {
          color: $secondary;
        }
      }

      .custom-control {
        .custom-control-input {
          &:focus:not(:checked) ~ .custom-control-label::before {
            border-color: $gray-500;
          }
          &:checked ~ .custom-control-label::before {
            box-shadow: none;
          }
        }

        .custom-control-label {
          &::before,
          &::after{
            cursor: pointer;
          }
        }
      }

      .feature-title {
        margin-bottom: 0.5rem;
        font-size: 1rem;
        cursor: pointer;
      }
    }

    .feature-options {
      display: flex;
      justify-content: space-between;

      .feature-price {
        font-size: 0.8rem;
      }

      .feature-options-icon {
        display: flex;

        svg {
          @include collapse-transition();
          margin: 0 0.2em;
          cursor: pointer;

          &.open {
            transform: rotate(180deg);
          }
        }
      }
    }

    .feature-details {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      @include collapse-transition();

      &.open {
        img {
          opacity: 1;
        }
      }

      .feature-description {
        margin: 0;
        font-size: 0.8rem;
      }

      .feature-url {
        margin: 0;
        font-size: 0.8rem;
        cursor: pointer;

        a {
          color: white;
        }
      }

      .feature-list {
        h5 {
          font-size: 1rem;
        }
      }

      img {
        height: 150px;
        width: 100%;
        object-fit: cover;
        opacity: 0;
        cursor: pointer;
        @include collapse-transition();
      }

      .gallery-container {
        width: 100%;
        height: auto;
        min-height: auto;

        .slick-slider {
          .slick-list {
            .slick-track {
              &::after {
                box-shadow: none;
              }
            }
          }
        }

        .category-nav {
          display: none;
        }
      }
    }
  }
}
