@import "~animate-scss/properties";
@import "~animate-scss/_fading-entrances/fadeIn";
@import "~animate-scss/_fading-exits/fadeOut";
@import "~animate-scss/_bouncing-entrances/bounceInDown";
@import "~animate-scss/_bouncing-exits/bounceOutDown";

.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;

  &.modal-overlay-enter-active {
    @include fadeIn();
  }

  &.modal-overlay-exit-active {
    @include fadeOut();
  }
}

.modal-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;

  &.modal-container-enter-active {
    @include bounceInDown();
  }

  &.modal-container-exit-active {
    @include bounceOutDown();
  }

  &.hidden {
    display: none;
  }

  .modal {
    padding: 1rem;
    background-color: white;
    border-radius: 1rem;
    z-index: 3;

    .modal-header {
      display: flex;
      justify-content: space-between;

      .modal-close {
        cursor: pointer;
      }
    }

    .modal-actions {
      display: flex;
      justify-content: flex-end;

      .btn {
        margin-left: 0.5rem;
        padding: 0.2rem 0.5rem;
      }
    }
  }
}
