@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.configurator-container {
  display: flex;
  flex-direction: column;
  height: auto;

  @include breakpoint(md) {
    flex-direction: row;
    height: 100%;
  }

  .configurator-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
