@import "../../../../assets/scss/mixins";

.basket-category {
  h4 {
    svg {
      @include collapse-transition();
      margin-left: 0.5rem;
      cursor: pointer;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  h5 {
    svg {
      margin-left: 0.5rem;
      cursor: pointer;
    }
  }

  .collapse-css-transition {
    @include collapse-transition();
  }

  ul {
    padding: 0;
    list-style-type: none;
  }
}
