@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.gallery-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  min-height: 50vh;
  min-width: 0;
  overflow: hidden;

  @include breakpoint(md) {
    min-height: 0;
  }

  .slider-container {
    position: relative;
    flex-grow: 1;

    .slider-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .btn-more-info {

      }
    }

    .slick-slider {
      height: 100%;

      .slick-list {
        height: 100%;

        .slick-track {
          height: 100%;

          &::after {
            @include box-shadow('inset');

            content: '';
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            width: 100%;
          }

          .slick-slide {
            > * {
              height: 100%;

              img {
                height: 100%;
                width: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }

      .slick-arrow {
        z-index: 2;
        margin: 0 1rem;
        width: 2rem;
        height: 2rem;

        &.slick-prev {
          left: 0;
        }
        &.slick-next {
          right: 0;
        }
        &.slick-prev,
        &.slick-next {
          display: flex !important;
          align-items: center;
          justify-content: center;
          padding: 1px;
          background-color: $white;
          border-radius: 50%;

          &::before {
            height: 30px;
            width: 32px;
            font-size: 2rem;
            color: $secondary;
          }
        }
      }
    }
  }
}
