@import "../../assets/scss/variables";

.products-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  h1 {
    color: $secondary;
  }

  .products-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5rem;
    justify-items: center;

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
